export default (selectPayload) => {
  const selection = selectPayload.selection[0]
  if (!selection) {
    return null
  }
  return {
    product: {
      id: selection.id,
      title: selection.title,
      image: selection.image ? selection.image : (selection.images && selection.images.length > 0 && selection.images[0]) ? selection.images[0].originalSrc : null,
      options: selection.options,
    },
    variants: selection.variants.map(variant => {return {
      id: variant.id,
      title: variant.title,
      price: variant.price,
      compareAtPrice: variant?.compareAtPrice,
      image: variant.image ? variant.image.originalSrc : null,
      inventoryPolicy: variant.inventoryPolicy,
      inventoryQuantity: variant.inventoryQuantity,
      tracksInventory: "NOT_MANAGED" === variant.inventoryManagement ? null : variant.inventoryManagement,
      availableForSale: variant.availableForSale
    }})
  }
}