import { axiosInterceptor } from './axiosInterceptor'

export default (token, shop, host, startDate, endDate, allDate) => {
  const axiosWithToken = axiosInterceptor(shop, host)
  const tzoffset = (new Date()).getTimezoneOffset() * 60000; 
  return axiosWithToken.request({
    url: `/analytics`,
    method: 'get',    
    headers: {
        'X-Shopify-Access-Token': token,
        'X-Shopify-Shop-Domain': shop,
        'X-Shopify-Start': (new Date(startDate.getTime() - tzoffset)).toISOString().slice(0,10),
        'X-Shopify-End': (new Date(endDate.getTime() - tzoffset)).toISOString().slice(0,10),
        'X-Shopify-All': allDate
    },
  })
}